import {
  ApiPaths,
  ApiService,
  SetAuthToken,
  ApiStatus,
  ErpApiService,
  ErpApi,
  FileUploadService,
} from "../../utils/ApiService";
import {
  GET_CIVIL_RESOURCE_PLANNING,
  GET_CIVIL_PROJECT_NAMES,
  GET_CIVIL_PROCESS_NAMES,
  GET_CIVIL_TASK_NAMES,
  GET_CIVIL_RESOURCE_NAMES,
  GET_CIVIL_RESOURCE_TYPE_PLANNING,
  GET_CIVIL_BUDGET_NAMES,
  GET_CIVIL_PROJECT,
  GET_CIVIL_PROJECT_PLANNIG_STAGE,
  GET_CIVIL_PROJECT_TEAM_ROLE,
  GET_CIVIL_PROJECT_PLAN_LIST,
  GET_CIVIL_PROJECT_PLAN_TEAM,
  GET_CIVIL_PROJECT_SITE_LOCATION,
  GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,
  GET_CIVIL_WBS,
  GET_CIVIL_PROJECT_PROCESS_PLAN,
  GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
  GET_CIVIL_PROJECT_PROCESS_TASK,
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
  GET_TASK_DEPENDENCY_TYPE,
  GET_CIVIL_PROJECT_ROLE,
  GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN,
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
  GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY,
  GET_CIVIL_MATERIAL,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,
  GET_CIVIL_PROJECT_ACTIVITY_MASTER,
  GET_CIVIL_PROJECT_TASK_UOM,
  GET_CIVIL_PROJECT_WBS,
  GET_CIVIL_PROJECT_TASK,
  GET_CIVIL_PROCESS_M,
  GET_CIVIL_RESOURCE,
  GET_CIVIL_RESOURCE_CATEGORY,
  GET_CIVIL_PROJECT_RESOURCE_1,
  GET_CIVIL_OBJECT_PRODUCTIVITY,
  GET_CIVIL_RESOURCE_ALL,
  GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
  GET_CIVIL_PROJECT_ACTIVITY_DETAIL,
  GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,
  GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY,
  GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY,
  GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_RESOURCE_EXPORT,
  COPY_CIVIL_PROJECT_RESOURCE,
  GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT,
  COPY_CIVIL_PROJECT_PRODUCTIVITY,
  GET_CIVIL_RESOURCE_EXPORT,
  GET_CIVIL_PROJECT_PLAN_LIST_EXPORT,
  GET_CIVIL_PROJECT_TASK_UOM_EXPORT,
  GET_CIVIL_PROCESS_M_EXPORT,
  GET_CIVIL_PROJECT_TASK_EXPORT,
  GET_CIVIL_PROJECT_ROLE_EXPORT,
  GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT,
  GET_CIVIL_PROJECT_ACTIVITY_EXPORT,
  GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT,
  GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,
  GET_CIVIL_PROJECT_BUDGET,
  GET_CIVIL_PROJECT_WBS_DETAIL,
  GET_CIVIL_PROJECT_PLAN_PENDING,
  GET_CIVIL_PROJECT_SCHEDULE,
  GET_CIVIL_RESOURCE_IDENTIFICATION,
  GET_CIVIL_MATERIAL_FOR_ACTIVITY,
  GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART,
  GET_CIVIL_PROJECT_PLAN_DETAIL,
  GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL,
  GET_WORK_ORDER_PROCESS,
  GET_WORK_ORDER_PROCESS_EXPORT,
  GET_WORK_ORDER_TASK,
  GET_WORK_ORDER_TASK_EXPORT,
  GET_PROFITABILITY_REPORT,
  GET_WORK_ORDER_PROJECT,
  GET_COMPANY_PROJECT_MAP,
  GET_VENDOR_TYPE,
  GET_WORK_ORDER_FILTER_VENDOR,
  GET_WORK_ORDER,
  GET_WORK_ORDER_TERMS,
  GET_WORK_ORDER_DETAIL,
  GET_WORK_ORDER_PROJECT_WBS,
  GET_WORK_ORDER_PROJECT_ACTIVITY,
  GET_WORK_ORDER_TASK_FOR_ITEM,
  GET_WORK_ORDER_ITEM,
  GET_WORK_ORDER_DETAIL_PRINT,
  GET_WORK_ORDER_FILTER_PROJECT,
  GET_WORK_ORDER_EXPORT,
  GET_WORK_ORDER_CATEGORY,
  GET_WORK_ORDER_CATEGORY_EXPORT,
  GET_CIVIL_PROJECT_ACTIVITY_PROGRESS,
  GET_VENDOR_MACHINE,
  GET_VENDOR_MACHINE_EXPORT,
  GET_VENDOR_MACHINE_DETAIL,
  GET_PROJECT_MACHINE_VENDOR_MAP,
  GET_PROJECT_MACHINE_VENDOR_MAP_EXPORT,
  GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL,
  GET_WORK_ORDER_TASK_PLAN_RESOURCE,
  GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE,
  GET_ACTUAL_ENTRY_VENDOR_MACHINE,
  GET_CIVIL_ACTUAL_ENTRY_MACHINE,
  GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
  GET_ACTIVITY_QUALITY_CHECK_POINT,
  GET_ACTIVITY_QUALITY_CHECK_POINT_EXPORT,
  GET_ACTUAL_REJECTION_REASON,
  GET_ACTUAL_REJECTION_REASON_EXPORT,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR,
  GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE,
  GET_ACTUAL_ENTRY_VENDOR_LABOUR,
  GET_CIVIL_ACTUAL_ENTRY_LABOUR,
  GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL,
  GET_ACTUAL_ENTRY_VENDOR_CONTRACT,
  GET_CIVIL_ACTUAL_ENTRY_CONTRACT,
  GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL,
  GET_GST,
  GET_TDS,
  GET_WORK_ORDER_FILTER_COMPANY,
  GET_VENDOR_MACHINE_FILTER_VENDOR,
  GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE,
  GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR,
  GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE,
  GET_ITEM_GROUP,
  GET_ITEM_GROUP_EXPORT,
  GET_MATERIAL,
  GET_ASSET_TYPE,
  GET_STOCK_UOM,
  GET_ASSET_GROUP,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE,
  GET_PROJECT_DOCUMENT_TYPE,
  GET_CIVIL_PROJECT_DRAWING_FILTER,
  GET_CIVIL_PROJECT_DRAWING,
  GET_CIVIL_WORK_INSPECTION,
  GET_CIVIL_WORK_INSPECTION_EXPORT,
  GET_CIVIL_WORK_INSPECTION_TASK_PLAN_FILTER_TASK,
  GET_CIVIL_WORK_INSPECTION_FILTER_PROCESS,
  GET_CIVIL_WORK_INSPECTION_FILTER_PROJECT,
  GET_CIVIL_WORK_INSPECTION_DETAIL,
  GET_CIVIL_QC_TRAINING,
  GET_CIVIL_QC_TRAINING_EXPORT,
  GET_CIVIL_QC_TRAINING_ATTENDEE,
  GET_CIVIL_QC_TRAINING_DETAIL,
  GET_CIVIL_QC_TRAINING_FILTER_PROJECT,
  GET_CIVIL_QC_TRAINING_FILTER_PROCESS,
  GET_CIVIL_QC_TRAINING_FILTER_TASK,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS,
  GET_CIVIL_PROJECT_APPROVAL,
  GET_CIVIL_PROJECT_BILL,
  GET_CIVIL_PROJECT_BILL_DETAIL,
  GET_CIVIL_PROJECT_BILL_FILTER_PROJECT,
  GET_CIVIL_PROJECT_BILL_FILTER_COMPANY,
  GET_CIVIL_PROJECT_BILL_FILTER_VENDOR,
  GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY,
  GET_CIVIL_PROJECT_PLAN_FILTER_PROJECTS1,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS,
  GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY,
  GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY,
  GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY,
  GET_CIVIL_SETTING,
  GET_CIVIL_SETTING_EXPORT,
  GET_CIVIL_PROJECT_SETTING,
  GET_CIVIL_PROJECT_SETTING_FILTER,
  GET_CIVIL_PROJECT_SETTING_DETAILS,
  GET_CIVIL_PROJECT_SETTING_EXPORT,
  GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT,
  GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL,
  GET_CIVIL_PROJECT_BILL_EXPORT,
  GET_PROJECT_BILL_ADVANCE_DEDUCTION_PERCENTAGE,
  GET_PROJECT_BILL_RETENTION_PERCENTAGE,
  GET_CIVIL_PROJECT_QUANTITY,
  GET_CIVIL_PROJECT_SCHEDULE_ESTIMATE,
} from "./types";
import {
  mainLoadingTrue,
  mainLoadingFalse,
  displayMessage,
} from "../User/action";

import { getItem, setItem } from "../../utils/crypto";

const userLocal = getItem("enuseloc");
const LoginuserId =
  userLocal && Object.keys(userLocal).length > 0 ? userLocal["user_id"] : null;

export const getResourcePlanning_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.CIVILRESOURCEPLANNING, data)
      .then((response) => {
        const { data } = response;
        console.log("scheduleData", data.scheduleData);
        dispatch(getCivilResourcePlanningSuccess(data.scheduleData));
        dispatch(mainLoadingFalse());
        // if(!data.error){
        //     dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
        // }
        // else{
        //     dispatch(getCivilResourcePlanningSuccess({}))
        // }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourcePlanningSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourcePlanningSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_PLANNING,
    payload: data,
  };
};

export const getResourceTypePlanning_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.CIVILRESOURCETYPEPLANNING, data)
      .then((response) => {
        const { data } = response;
        console.log("scheduleData", data.scheduleData);
        dispatch(getCivilResourceTypePlanningSuccess(data.scheduleData));
        dispatch(mainLoadingFalse());
        // if(!data.error){
        //     dispatch(getCivilResourcePlanningSuccess(data.scheduleData))
        // }
        // else{
        //     dispatch(getCivilResourcePlanningSuccess({}))
        // }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourceTypePlanningSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceTypePlanningSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_TYPE_PLANNING,
    payload: data,
  };
};

export const getProjectsNames_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GETCIVILPROJECTS, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectsNamesSuccess(data.projectData));
        } else {
          dispatch(getProjectsNamesSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectsNamesSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectsNamesSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_NAMES,
    payload: data,
  };
};

export const getResourceNames_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GETCIVILRESOURCE, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getResourceNamesSuccess(data.resourceData));
        } else {
          dispatch(getResourceNamesSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getResourceNamesSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getResourceNamesSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_NAMES,
    payload: data,
  };
};

export const getCivilProcess_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GETCIVILPROCESS, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProcessSuccess(data.processData));
        } else {
          dispatch(getCivilProcessSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProcessSuccess = (data) => {
  return {
    type: GET_CIVIL_PROCESS_NAMES,
    payload: data,
  };
};

export const getCivilTasks_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GETCIVILTASKS, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilTasksSuccess(data.taskData));
        } else {
          dispatch(getCivilTasksSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilTasksSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilTasksSuccess = (data) => {
  return {
    type: GET_CIVIL_TASK_NAMES,
    payload: data,
  };
};

export const getCivilBudget_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GETCIVILBUDGET, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilBudgetSuccess(data.scheduleData));
        } else {
          dispatch(getCivilBudgetSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilBudgetSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilBudgetSuccess = (data) => {
  return {
    type: GET_CIVIL_BUDGET_NAMES,
    payload: data,
  };
};

export const getCivilProgress_action = (data) => {
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ApiService.post(ApiPaths.GETCIVILPROGRESS, data)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProgressSuccess(data.scheduleData));
        } else {
          dispatch(getCivilProgressSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProgressSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProgressSuccess = (data) => {
  return {
    type: GET_CIVIL_BUDGET_NAMES,
    payload: data,
  };
};

export const getCivilProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSuccess(data));
        } else {
          dispatch(getCivilProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT,
    payload: data,
  };
};

export const getCivilProjectPlanningStage_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLANNIG_STAGE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanningStageSuccess(data));
        } else {
          dispatch(getCivilProjectPlanningStageSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanningStageSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanningStageSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLANNIG_STAGE,
    payload: data,
  };
};

export const createCivilProjectPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    projectID: "",
    planningStageID: "",
    planningExtent: "",
    developmentArea: "",
    developmentBudget: "",
    plannedStartDate: "",
    plannedEndDate: "",
    plannedDuration: "",
    planningRemark: "",

    project_problems: "",
    project_purpose: "",
    project_goals: "",
    project_deliverable: "",
    project_specifications: "",
    specification_in_scrope: "",
    specification_out_scrope: "",
    process_owner: "",
    key_stakeholder: "",
    final_customer: "",
    expected_benefits: "",
    risks: "",
    constraints: "",
    assumptions: "",
    totalDevelopmentArea: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTeamRole_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TEAM_ROLE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTeamRoleSuccess(data));
        } else {
          dispatch(getCivilProjectTeamRoleSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTeamRoleSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTeamRoleSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TEAM_ROLE,
    payload: data,
  };
};

export const getCivilProjectPlanList_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    stageID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_LIST,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanListSuccess(data));
        } else {
          dispatch(getCivilProjectPlanListSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanListSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanListSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_LIST,
    payload: data,
  };
};

export const createCivilProjectTeam_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    roleID: "",
    memberID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TEAM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const createCivilProjectLocation_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectLocationID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_LOCATION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanTeam_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_TEAM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanTeamSuccess(data));
        } else {
          dispatch(getCivilProjectPlanTeamSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanTeamSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanTeamSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_TEAM,
    payload: data,
  };
};

export const getCivilProjectSiteLocation_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_SITE_LOCATION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSiteLocationSuccess(data));
        } else {
          dispatch(getCivilProjectSiteLocationSuccess([]));
        }
        return response?.data;
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSiteLocationSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSiteLocationSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SITE_LOCATION,
    payload: data,
  };
};

export const getCivilProjectPlanFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // "projectPlanID":"",
    // "status":"",
    // "generalSearch":"",
    // "sortOrder":"",
    // "iDisplayStart":0,
    // "iDisplayLength":-1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanFilterProjectSuccess(data));
        } else {
          dispatch(getCivilProjectPlanFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanFilterProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,
    payload: data,
  };
};

export const getCivilWbs_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_WBS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWbsSuccess(data));
        } else {
          dispatch(getCivilWbsSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWbsSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWbsSuccess = (data) => {
  return {
    type: GET_CIVIL_WBS,
    payload: data,
  };
};

export const createCivilProjectWbs_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_PROCESS_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPlanID: "",
    processID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectProcessPlanSuccess(data));
        } else {
          dispatch(getCivilProjectProcessPlanSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectProcessPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessPlanSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PROCESS_PLAN,
    payload: data,
  };
};

export const getCivilProjectProcessPlanFilterProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectProcessPlanFilterProcessSuccess(data));
        } else {
          dispatch(getCivilProjectProcessPlanFilterProcessSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectProcessPlanFilterProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessPlanFilterProcessSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
    payload: data,
  };
};

export const getCivilProjectProcessTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectProcessTaskSuccess(data));
        } else {
          dispatch(getCivilProjectProcessTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectProcessTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessTaskSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PROCESS_TASK,
    payload: data,
  };
};

export const getCivilProjectProcessTaskPlanFilterTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    taskID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectProcessTaskPlanFilterTaskSuccess(data));
        } else {
          dispatch(getCivilProjectProcessTaskPlanFilterTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectProcessTaskPlanFilterTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessTaskPlanFilterTaskSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
    payload: data,
  };
};

export const getTaskDependencyType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_TASK_DEPENDENCY_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getTaskDependencyTypeSuccess(data));
        } else {
          dispatch(getTaskDependencyTypeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getTaskDependencyTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getTaskDependencyTypeSuccess = (data) => {
  return {
    type: GET_TASK_DEPENDENCY_TYPE,
    payload: data,
  };
};

export const getCivilProjectRole_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    roleStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ROLE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectRoleSuccess(data));
        } else {
          dispatch(getCivilProjectRoleSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectRoleSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectRoleSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ROLE,
    payload: data,
  };
};

export const updateCivilProjectRoleStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    roleID: "",
    roleName: "",
    roleStatus: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_ROLE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const createCivilProjectRole_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    roleName: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_ROLE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const createCivilProjectProcessTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_PROCESS_TASK_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanFilterStage_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanFilterStageSuccess(data));
        } else {
          dispatch(getCivilProjectPlanFilterStageSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanFilterStageSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanFilterStageSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,
    payload: data,
  };
};

//Schedule get
export const getCivilProjectProcessTaskPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPlanID: "",
    processPlanID: "",
    taskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ACTIVITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectProcessTaskPlanSuccess(data));
        } else {
          dispatch(getCivilProjectProcessTaskPlanSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectProcessTaskPlanSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessTaskPlanSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PROCESS_TASK_PLAN,
    payload: data,
  };
};

export const getCivilProjectTaskPlanLocationQuantity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanLocationQuantitySuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanLocationQuantitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanLocationQuantitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanLocationQuantitySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
    payload: data,
  };
};

export const createCivilProjectTaskPlanQuantity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    taskPlanQuantity: "",
    locationQuantityID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_TASK_PLAN_QUANTITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskMaterialCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskMaterialCategorySuccess(data));
        } else {
          dispatch(getCivilProjectTaskMaterialCategorySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskMaterialCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskMaterialCategorySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY,
    payload: data,
  };
};

export const getCivilMaterial_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_MATERIAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilMaterialSuccess(data));
        } else {
          dispatch(getCivilMaterialSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilMaterialSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilMaterialSuccess = (data) => {
  return {
    type: GET_CIVIL_MATERIAL,
    payload: data,
  };
};

export const createCivilProjectTaskPlanMaterial_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    materialID: "",
    materialQuantity: "",
    budgetedRate: "",
    materialDescription: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_PLAN_MATERIAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectTaskPlanMaterial_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialDelete_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanMaterialID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_DELETE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityMaster_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_ACTIVITY_MASTER,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectActivityMasterSuccess(data));
        } else {
          dispatch(getCivilProjectActivityMasterSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectActivityMasterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityMasterSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ACTIVITY_MASTER,
    payload: data,
  };
};
export const getCivilProjectTaskUOM_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskUomStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_UOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskUOMSuccess(data));
        } else {
          dispatch(getCivilProjectTaskUOMSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskUOMSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskUOMSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_UOM,
    payload: data,
  };
};

export const createCivilProjectTaskUOM_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskUomName: "",
    uomRemark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_TASK_UOM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectTaskUOM_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    uomID: "",
    taskUomName: "",
    uomRemark: "",
    uomStatus: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_UOM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    planningStageID: "",
    planningExtent: "",
    developmentArea: "",
    developmentBudget: "",
    plannedStartDate: "",
    plannedEndDate: "",
    plannedDuration: "",
    planningRemark: "",

    project_problems: "",
    project_purpose: "",
    project_goals: "",
    project_deliverable: "",
    project_specifications: "",
    specification_in_scrope: "",
    specification_out_scrope: "",
    process_owner: "",
    key_stakeholder: "",
    final_customer: "",
    expected_benefits: "",
    risks: "",
    constraints: "",
    assumptions: "",

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_PLAN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectWbs_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    statusID: "",
    generalSearch: "",
    projectID: "",
    taskID: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_WBS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectWbsSuccess(data));
        } else {
          dispatch(getCivilProjectWbsSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectWbsSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectWbsSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_WBS,
    payload: data,
  };
};
export const getCivilProjectTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskStatus: "",
    processID: "",
    taskCategory: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskSuccess(data));
        } else {
          dispatch(getCivilProjectTaskSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK,
    payload: data,
  };
};

export const createCivilProjectTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskUomID: "",
    taskOrder: "",
    taskName: "",
    processID: "",
    taskRemark: "",
    taskCategory: "Schedule",
    taskDescription: "",
    taskCode: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updatetCivilProjectTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskID: "",
    taskName: "",
    taskUomID: "",
    taskOrder: "",
    processID: "",
    taskRemark: "",
    taskStatus: "",
    taskCode: "",
    taskCategory: "Schedule",
    taskDescription: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProcesslist_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    parentID: "",
    processIsRoad: "",
    processStatus: "",
    generalSearch: "",
    category: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROCESS_M, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProcesslistSuccess(data));
        } else {
          dispatch(getCivilProcesslistSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProcesslistSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProcesslistSuccess = (data) => {
  return {
    type: GET_CIVIL_PROCESS_M,
    payload: data,
  };
};
export const createCivilProjectProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    parentID: "",
    processName: "",
    processOrder: "",
    processIsRoad: "",
    projectProgressStatus: "",
    processRemark: "",
    category: "Schedule",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    parentID: "",
    processID: "",
    processName: "",
    processOrder: "",
    processIsRoad: "",
    projectProgressStatus: "",
    processRemark: "",
    processStatus: "",
    category: "Schedule",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilResourceSuccess(data));
        } else {
          dispatch(getCivilResourceSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE,
    payload: data,
  };
};

export const createCivilResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    name: "",
    remark: "",
    typeColor: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_RESOURCE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    resourceID: "",

    name: "",
    status: "",
    remark: "",
    typeColor: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_RESOURCE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_RESOURCE_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilResourceCategorySuccess(data));
        } else {
          dispatch(getCivilResourceCategorySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourceCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceCategorySuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_CATEGORY,
    payload: data,
  };
};

export const getCivilProjectResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    resourceCategoryID: "",
    resourceID: "",
    uomID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_RESOURCE_1,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectResourceSuccess(data));
        } else {
          dispatch(getCivilProjectResourceSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectResourceSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_RESOURCE_1,
    payload: data,
  };
};

export const updateCivilProjectResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectResourceID: "",
    resourceRate: "",
    uomID: "",
    remark: "",
    status: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const createCivilObjectProductivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // "projectPlanID": "",
    // "taskID":"",
    // "resourceID": "",
    // "outputPerHr": "",
    // "remark": "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_RESOURCE_PRODUCTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectResourceProductivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processID: "",
    taskID: "",
    resourceCategoryID: "",
    resourceID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_OBJECT_PRODUCTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilObjectProductivitySuccess(data));
        } else {
          dispatch(getCivilObjectProductivitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilObjectProductivitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilObjectProductivitySuccess = (data) => {
  return {
    type: GET_CIVIL_OBJECT_PRODUCTIVITY,
    payload: data,
  };
};

export const createCivilProjectResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    resourceID: "",
    resourceRate: "",
    uom: "",
    remark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCivilProjectTaskPlanResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    projectResourceID: "",
    plannedQuantity: "",
    resourceDescription: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilResourceAll_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE_ALL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilResourceAllSuccess(data));
        } else {
          dispatch(getCivilResourceAllSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourceAllSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceAllSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_ALL,
    payload: data,
  };
};
export const getCivilProjectTaskPlanResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceCategoryID: "",
    resourceID: "",
    statusID: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanResourceSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanResourceSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanResourceSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
    payload: data,
  };
};
export const updateCivilProjectTaskPlanDuration_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    plannedDuration: "",
    taskPlanOrder: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_DURATION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const refreshCivilActivity_action = () => {
  return {
    type: "REFRESH_CIVIL_PROJECT_ACTIVITY",
  };
};

export const deselectRowSelection_action = () => {
  return {
    type: "DESELECT_ROW_CIVIL_PROJECT_ACTIVITY",
  };
};

export const getCivilProjectActivityDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_ACTIVITY_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectActivityDetailSuccess(data));
        } else {
          dispatch(getCivilProjectActivityDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectActivityDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ACTIVITY_DETAIL,
    payload: data,
  };
};

export const createCivilProjectTaskPlanDependancy_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    relationshipID: "",
    dependantTaskPlanID: "",
    lagLead: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectDependableActivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectDependableActivitySuccess(data));
        } else {
          dispatch(getCivilProjectDependableActivitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectDependableActivitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectDependableActivitySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,
    payload: data,
  };
};

export const getCivilProjectResourceFilterResourceCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectResourceFilterResourceCategorySuccess(data));
        } else {
          dispatch(getCivilProjectResourceFilterResourceCategorySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectResourceFilterResourceCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectResourceFilterResourceCategorySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY,
    payload: data,
  };
};

export const getCivilProjectResourceFilterResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    resourceCategoryID: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectResourceFilterResourceSuccess(data));
        } else {
          dispatch(getCivilProjectResourceFilterResourceSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectResourceFilterResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectResourceFilterResourceSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
    payload: data,
  };
};

export const getCivilProjectResourceProductivityFilterResourceCategory_action =
  (data) => {
    const initialPayload = {
      userID: LoginuserId,
      projectPlanID: "",
      processID: "",
      taskID: "",
      ...data,
    };

    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(
        ErpApi.GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());

          if (!data.error) {
            dispatch(
              getCivilProjectResourceProductivityFilterResourceCategorySuccess(
                data
              )
            );
          } else {
            dispatch(
              getCivilProjectResourceProductivityFilterResourceCategorySuccess(
                []
              )
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(
            getCivilProjectResourceProductivityFilterResourceCategorySuccess([])
          );
          dispatch(mainLoadingFalse());
        });
    };
  };

export const getCivilProjectResourceProductivityFilterResourceCategorySuccess =
  (data) => {
    return {
      type: GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY,
      payload: data,
    };
  };

export const getCivilProjectResourceProductivityFilterResource_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processID: "",
    taskID: "",
    resourceCategoryID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            getCivilProjectResourceProductivityFilterResourceSuccess(data)
          );
        } else {
          dispatch(
            getCivilProjectResourceProductivityFilterResourceSuccess([])
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectResourceProductivityFilterResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectResourceProductivityFilterResourceSuccess = (
  data
) => {
  return {
    type: GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE,
    payload: data,
  };
};

// already available changes
export const updateCivilObjectProductivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    resourceProductivityID: "",
    outputPerHr: "",
    remark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectResourceExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    resourceCategoryID: "",
    resourceID: "",
    uomID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_RESOURCE_1,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectResourceExportSuccess(data));
        } else {
          dispatch(getCivilProjectResourceExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectResourceExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectResourceExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_RESOURCE_EXPORT,
    payload: data,
  };
};

export const copyCivilProjectResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromProjectPlanID: "",
    toProjectPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.COPY_CIVIL_PROJECT_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(copyCivilProjectResourceSuccess(data));
        } else {
          dispatch(copyCivilProjectResourceSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(copyCivilProjectResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const copyCivilProjectResourceSuccess = (data) => {
  return {
    type: COPY_CIVIL_PROJECT_RESOURCE,
    payload: data,
  };
};

export const getCivilProjectResourceProductivityExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processID: "",
    taskID: "",
    resourceCategoryID: "",
    resourceID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_OBJECT_PRODUCTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectResourceProductivityExportSuccess(data));
        } else {
          dispatch(getCivilProjectResourceProductivityExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectResourceProductivityExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectResourceProductivityExportSuccess = (data) => {
  return {
    type: GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT,
    payload: data,
  };
};

export const copyCivilResourceProductivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromProjectPlanID: "",
    toProjectPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.COPY_CIVIL_PROJECT_PRODUCTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(copyCivilResourceProductivitySuccess(data));
        } else {
          dispatch(copyCivilResourceProductivitySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(copyCivilResourceProductivitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const copyCivilResourceProductivitySuccess = (data) => {
  return {
    type: COPY_CIVIL_PROJECT_PRODUCTIVITY,
    payload: data,
  };
};

export const importCivilResourceList_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);

  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_LIAISON_DOC_CHECKLIST_PLAN,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const importCivilProjectResource_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);

  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_CIVIL_PROJECT_RESOURCE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const importCivilProjectResourceProductivity_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);

  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_RESOURCE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilResourceExportSuccess(data));
        } else {
          dispatch(getCivilResourceExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourceExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilResourceExportSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_EXPORT,
    payload: data,
  };
};

export const getCivilProjectPlanListExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    stageID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_LIST,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanListExportSuccess(data));
        } else {
          dispatch(getCivilProjectPlanListExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanListExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanListExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_LIST_EXPORT,
    payload: data,
  };
};

export const getCivilProjectTaskUOMExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskUomStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK_UOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskUOMExportSuccess(data));
        } else {
          dispatch(getCivilProjectTaskUOMExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskUOMExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskUOMExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_UOM_EXPORT,
    payload: data,
  };
};

export const getCivilProcesslistExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processIsRoad: "",
    processStatus: "",
    category: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROCESS_M, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProcesslistExportSuccess(data));
        } else {
          dispatch(getCivilProcesslistExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProcesslistExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProcesslistExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROCESS_M_EXPORT,
    payload: data,
  };
};

export const getCivilProjectTaskExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    taskStatus: "",
    taskCategory: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskExportSuccess(data));
        } else {
          dispatch(getCivilProjectTaskExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_EXPORT,
    payload: data,
  };
};

export const getCivilProjectRoleExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    roleStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ROLE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectRoleExportSuccess(data));
        } else {
          dispatch(getCivilProjectRoleExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectRoleExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectRoleExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ROLE_EXPORT,
    payload: data,
  };
};

export const getCivilProjectTaskPlanResourceExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceCategoryID: "",
    resourceID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectTaskPlanResourceExportSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanResourceExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanResourceExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectTaskPlanResourceExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT,
    payload: data,
  };
};

export const getCivilProjectProcessTaskPlanExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPlanID: "",
    processPlanID: "",
    taskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_ACTIVITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectProcessTaskPlanExportSuccess(data));
        } else {
          dispatch(getCivilProjectProcessTaskPlanExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectProcessTaskPlanExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectProcessTaskPlanExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ACTIVITY_EXPORT,
    payload: data,
  };
};

export const getCivilProjectTaskPlanQuantityExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanQuantityExportSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanQuantityExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanQuantityExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanQuantityExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT,
    payload: data,
  };
};

export const importCivilProjectTaskPlanQuantityExport_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);

  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const importCivilProjectTaskPlanQuantity_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);

  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_CIVIL_PROJECT_TASK_PLAN_QUANTITY,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const importCivilProjectTaskPlanResource_action = (data) => {
  let initialPayload = new FormData();
  initialPayload.append("userID", LoginuserId);
  initialPayload.append("documentFile", data.documentFile);

  // console.log("data", initialPayload);
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.IMPORT_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectTaskPlanCivilResourceExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanCivilResourceExportSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanCivilResourceExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanCivilResourceExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanCivilResourceExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,
    payload: data,
  };
};

export const updateCivilProjectTaskPlanSchedule_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_SCHEDULE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

//Schedule
export const getCivilProjectBudget_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BUDGET, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectBudgetSuccess(data));
        } else {
          dispatch(getCivilProjectBudgetSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectBudgetSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectBudgetSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_BUDGET,
    payload: data,
  };
};

export const getCivilProjectQuantity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_QUANTITY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectQuantitySuccess(data));
        } else {
          dispatch(getCivilProjectQuantitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectQuantitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectQuantitySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_QUANTITY,
    payload: data,
  };
};

export const getCivilProjectScheduleEstimate_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "",
    iDisplayLength: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SCHEDULE_ESTIMATE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectScheduleEstimateSuccess(data));
        } else {
          dispatch(getCivilProjectScheduleEstimateSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectScheduleEstimateSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectScheduleEstimateSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SCHEDULE_ESTIMATE,
    payload: data,
  };
};

export const getCivilProjectActivityDelete_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CIVIL_PROJECT_ACTIVITY_DELETE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );

          return "success";
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
          return "failed";
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectWbsDelete_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CIVIL_PROJECT_WBS_DELETE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
          return "success";
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
          return "failed";
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilProjectWbsDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_WBS_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectWbsDetailSuccess(data));
        } else {
          dispatch(getCivilProjectWbsDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectWbsDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectWbsDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_WBS_DETAIL,
    payload: data,
  };
};

export const updateCivilProjectProcessPlan_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    processPlanOrder: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_PROCESS_PLAN,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );

          return "success";
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
          return "failed";
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanPending_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_PENDING,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanPendingSuccess(data));
        } else {
          dispatch(getCivilProjectPlanPendingSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanPendingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanPendingSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_PENDING,
    payload: data,
  };
};

export const deleteCivilProjectTaskPlanDependancy_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    dependancyID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectTaskPlanDependancy_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    dependancyID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_DEPENDENCY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSchedule_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SCHEDULE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectScheduleSuccess(data));
        } else {
          dispatch(getCivilProjectScheduleSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectScheduleSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectScheduleSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SCHEDULE,
    payload: data,
  };
};

export const civilProjectWbsDeleteBulk_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CIVIL_PROJECT_WBS_DELETE_BULK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const civilProjectActivityDeleteBulk_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CIVIL_PROJECT_ACTIVITY_DELETE_BULK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilResourceIdentification_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_RESOURCE_FOR_ACTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilResourceIdentificationSuccess(data));
        } else {
          dispatch(getCivilResourceIdentificationSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilResourceIdentificationSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilResourceIdentificationSuccess = (data) => {
  return {
    type: GET_CIVIL_RESOURCE_IDENTIFICATION,
    payload: data,
  };
};

export const getCivilMaterialForActivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_MATERIAL_FOR_ACTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilMaterialForActivitySuccess(data));
        } else {
          dispatch(getCivilMaterialForActivitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilMaterialForActivitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilMaterialForActivitySuccess = (data) => {
  return {
    type: GET_CIVIL_MATERIAL_FOR_ACTIVITY,
    payload: data,
  };
};

export const deleteCivilProjectTaskPlanResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    resourcePlanID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.DELETE_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityGanttChart_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectActivityGanttChartSuccess(data));
        } else {
          dispatch(getCivilProjectActivityGanttChartSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectActivityGanttChartSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityGanttChartSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART,
    payload: data,
  };
};

export const getCivilProjectPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanDetailSuccess(data));
        } else {
          dispatch(getCivilProjectPlanDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_DETAIL,
    payload: data,
  };
};

export const getCivilProjectPlanFromTaskPlanDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectPlanFromTaskPlanDetailSuccess(data));
        } else {
          dispatch(getCivilProjectPlanFromTaskPlanDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectPlanFromTaskPlanDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectPlanFromTaskPlanDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL,
    payload: data,
  };
};
export const getCivilWorkOrderProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkOrderProcessSuccess(data));
        } else {
          dispatch(getCivilWorkOrderProcessSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkOrderProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderProcessSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_PROCESS,
    payload: data,
  };
};

export const createCivilWorkOrderProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processName: "",
    processOrder: "",
    remarks: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_WORK_ORDER_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilWorkOrderProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    processName: "",
    processOrder: "",
    status: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_WORK_ORDER_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderProcessExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_PROCESS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkOrderProcessExportSuccess(data));
        } else {
          dispatch(getCivilWorkOrderProcessExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkOrderProcessExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderProcessExportSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_PROCESS_EXPORT,
    payload: data,
  };
};

export const getCivilWorkOrderTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    taskUomID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkOrderTaskSuccess(data));
        } else {
          dispatch(getCivilWorkOrderTaskSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkOrderTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderTaskSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_TASK,
    payload: data,
  };
};

export const getCivilWorkOrderTaskExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    taskUomID: "",
    categoryID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkOrderTaskExportSuccess(data));
        } else {
          dispatch(getCivilWorkOrderTaskExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkOrderTaskExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderTaskExportSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_TASK_EXPORT,
    payload: data,
  };
};

export const createCivilWorkOrderTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskName: "",
    taskUomID: "",
    categoryID: "",
    remarks: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_WORK_ORDER_TASK, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilWorkOrderTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskID: "",
    taskName: "",
    taskOrder: "",
    taskUomID: "",
    categoryID: "",
    status: "",

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_WORK_ORDER_Task, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProfitabilityReport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    scaleType: "",
    recordCount: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROFITABILITY_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProfitabilityReportSuccess(data));
        } else {
          dispatch(getProfitabilityReportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProfitabilityReportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProfitabilityReportSuccess = (data) => {
  return {
    type: GET_PROFITABILITY_REPORT,
    payload: data,
  };
};

export const getProfitabilityReportExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    scaleType: "",
    recordCount: "",
    ...data,
  };
  return (dispatch) => {
    return ErpApiService.post(ErpApi.GET_PROFITABILITY_REPORT, initialPayload)
      .then((response) => {
        const { data } = response;
        if (!data.error) {
          return data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        return [];
      });
  };
};

//civil-work-order

export const getWorkOrderProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_PROJECT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderProjectSuccess(data));
        } else {
          dispatch(getWorkOrderProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderProjectSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_PROJECT,
    payload: data,
  };
};

export const getCompanyProjectMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    mapStatus: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_COMPANY_PROJECT_MAP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCompanyProjectMapSuccess(data));
        } else {
          dispatch(getCompanyProjectMapSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCompanyProjectMapSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCompanyProjectMapSuccess = (data) => {
  return {
    type: GET_COMPANY_PROJECT_MAP,
    payload: data,
  };
};

export const getVendorType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_VENDOR_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getVendorTypeSuccess(data));
        } else {
          dispatch(getVendorTypeSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVendorTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getVendorTypeSuccess = (data) => {
  return {
    type: GET_VENDOR_TYPE,
    payload: data,
  };
};

export const getWorkOrderFilterVendor_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_WORK_ORDER_FILTER_VENDOR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderFilterVendorSuccess(data));
        } else {
          dispatch(getWorkOrderFilterVendorSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderFilterVendorSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderFilterVendorSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_FILTER_VENDOR,
    payload: data,
  };
};

export const createWorkOrder_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    companyID: "",
    vendorID: "",
    validFromDate: "",
    validToDate: "",
    remark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_WORK_ORDER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrder_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    companyID: "",
    vendorTypeID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortParameter: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderSuccess(data));
        } else {
          dispatch(getWorkOrderSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderSuccess = (data) => {
  return {
    type: GET_WORK_ORDER,
    payload: data,
  };
};

export const updateWorkOrder_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    companyID: "",
    vendorID: "",
    validFromDate: "",
    validToDate: "",
    remark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_WORK_ORDER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const createWorkOrderTerm_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    workOrderTerms: "",
    displayOrder: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_WORK_ORDER_TERM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderTerms_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_TERMS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderTermsSuccess(data));
        } else {
          dispatch(getWorkOrderTermsSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderTermsSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderTermsSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_TERMS,
    payload: data,
  };
};

export const deleteWorkOrderTerm_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderTermID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_WORK_ORDER_TERM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderDetailSuccess(data));
        } else {
          dispatch(getWorkOrderDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderDetailSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_DETAIL,
    payload: data,
  };
};

export const getWorkOrderProjectWbs_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_PROJECT_WBS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderProjectWbsSuccess(data));
        } else {
          dispatch(getWorkOrderProjectWbsSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderProjectWbsSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderProjectWbsSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_PROJECT_WBS,
    payload: data,
  };
};

export const getWorkOrderProjectActivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_WORK_ORDER_PROJECT_ACTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderProjectActivitySuccess(data));
        } else {
          dispatch(getWorkOrderProjectActivitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderProjectActivitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderProjectActivitySuccess = (data) => {
  return {
    type: GET_WORK_ORDER_PROJECT_ACTIVITY,
    payload: data,
  };
};

export const getWorkOrderTaskForItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_WORK_ORDER_TASK_FOR_ITEM,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderTaskForItemSuccess(data));
        } else {
          dispatch(getWorkOrderTaskForItemSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderTaskForItemSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderTaskForItemSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_TASK_FOR_ITEM,
    payload: data,
  };
};

export const createWorkOrderItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    workOrderItemID: [],
    civilProjectTaskPlanID: "",
    remark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_WORK_ORDER_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderItemSuccess(data));
        } else {
          dispatch(getWorkOrderItemSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderItemSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderItemSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_ITEM,
    payload: data,
  };
};

export const deleteWorkOrderItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderItemID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.DELETE_WORK_ORDER_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderDetailPrint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    ...data,
  };
  return (dispatch) => {
    // dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        // dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getWorkOrderDetailPrintSuccess(data));
        } else {
          dispatch(getWorkOrderDetailPrintSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderDetailPrintSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getWorkOrderDetailPrintSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_DETAIL_PRINT,
    payload: data,
  };
};

export const getWorkOrderFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    companyID: "",
    vendorTypeID: "",
    vendorID: "",
    status: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_WORK_ORDER_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderFilterProjectSuccess(data));
        } else {
          dispatch(getWorkOrderFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderFilterProjectSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_FILTER_PROJECT,
    payload: data,
  };
};

export const getWorkOrderExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    companyID: "",
    vendorTypeID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortParameter: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderExportSuccess(data));
        } else {
          dispatch(getWorkOrderExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderExportSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_EXPORT,
    payload: data,
  };
};

export const updateWorkOrderStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    workOrderID: "",
    workOrderStatus: "",
    remark: "",
    rejectionReasonID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_WORK_ORDER_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkOrderCategorySuccess(data));
        } else {
          dispatch(getCivilWorkOrderCategorySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkOrderCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderCategorySuccess = (data) => {
  return {
    type: GET_WORK_ORDER_CATEGORY,
    payload: data,
  };
};

export const getCivilWorkOrderCategoryExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_WORK_ORDER_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkOrderCategoryExportSuccess(data));
        } else {
          dispatch(getCivilWorkOrderCategoryExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkOrderCategoryExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkOrderCategoryExportSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_CATEGORY_EXPORT,
    payload: data,
  };
};

export const createCivilWorkOrderCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryCode: "",
    categoryName: "",
    remarks: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_WORK_ORDER_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilWorkOrderCategory_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    categoryID: "",
    categoryName: "",
    categoryCode: "",
    remarks: "",
    status: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_WORK_ORDER_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityProgress_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_ACTIVITY_PROGRESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectActivityProgressSuccess(data));
        } else {
          dispatch(getCivilProjectActivityProgressSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectActivityProgressSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityProgressSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ACTIVITY_PROGRESS,
    payload: data,
  };
};

export const createVendorMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_VENDOR_MACHINE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getVendorMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    machineTypeID: "",
    vendorID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_VENDOR_MACHINE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getVendorMachineSuccess(data));
        } else {
          dispatch(getVendorMachineSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVendorMachineSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getVendorMachineSuccess = (data) => {
  return {
    type: GET_VENDOR_MACHINE,
    payload: data,
  };
};
export const getVendorMachineExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    machineTypeID: "",
    vendorID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_VENDOR_MACHINE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getVendorMachineExportSuccess(data));
        } else {
          dispatch(getVendorMachineExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVendorMachineExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getVendorMachineExportSuccess = (data) => {
  return {
    type: GET_VENDOR_MACHINE_EXPORT,
    payload: data,
  };
};
export const getVendorMachineDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    vendorMachineID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_VENDOR_MACHINE_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getVendorMachineDetailSuccess(data));
        } else {
          dispatch(getVendorMachineDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVendorMachineDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getVendorMachineDetailSuccess = (data) => {
  return {
    type: GET_VENDOR_MACHINE_DETAIL,
    payload: data,
  };
};
export const updateVendorMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPADTE_VENDOR_MACHINE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createProjectMachineVendorMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_PROJECT_MACHINE_VENDOR_MAP,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMachineVendorMap_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    machineTypeID: "",
    vendorID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_MACHINE_VENDOR_MAP,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectMachineVendorMapSuccess(data));
        } else {
          dispatch(getProjectMachineVendorMapSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMachineVendorMapSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMachineVendorMapSuccess = (data) => {
  return {
    type: GET_PROJECT_MACHINE_VENDOR_MAP,
    payload: data,
  };
};
export const getProjectMachineVendorMapExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    machineTypeID: "",
    vendorID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_MACHINE_VENDOR_MAP,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectMachineVendorMapExportSuccess(data));
        } else {
          dispatch(getProjectMachineVendorMapExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMachineVendorMapExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMachineVendorMapExportSuccess = (data) => {
  return {
    type: GET_PROJECT_MACHINE_VENDOR_MAP_EXPORT,
    payload: data,
  };
};
export const getProjectMachineVendorMapDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectMachineVendorMapID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectMachineVendorMapDetailSuccess(data));
        } else {
          dispatch(getProjectMachineVendorMapDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMachineVendorMapDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectMachineVendorMapDetailSuccess = (data) => {
  return {
    type: GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL,
    payload: data,
  };
};
export const updateProjectMachineVendorMapStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectMachineVendorMapID: "",

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_PROJECT_MACHINE_VENDOR_MAP_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderTaskPlanResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    workOrderID: "",
    resourceCategoryID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_WORK_ORDER_TASK_PLAN_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderTaskPlanResourceSuccess(data));
        } else {
          dispatch(getWorkOrderTaskPlanResourceSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderTaskPlanResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderTaskPlanResourceSuccess = (data) => {
  return {
    type: GET_WORK_ORDER_TASK_PLAN_RESOURCE,
    payload: data,
  };
};

export const getActualEntryTaskPlanResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    resourceCategoryID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActualEntryTaskPlanResourceSuccess(data));
        } else {
          dispatch(getActualEntryTaskPlanResourceSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActualEntryTaskPlanResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getActualEntryTaskPlanResourceSuccess = (data) => {
  return {
    type: GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE,
    payload: data,
  };
};

export const getActualEntryVendorMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    resourceID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTUAL_ENTRY_VENDOR_MACHINE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActualEntryVendorMachineSuccess(data));
        } else {
          dispatch(getActualEntryVendorMachineSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActualEntryVendorMachineSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getActualEntryVendorMachineSuccess = (data) => {
  return {
    type: GET_ACTUAL_ENTRY_VENDOR_MACHINE,
    payload: data,
  };
};

export const createCivilActualEntryMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    resourceTypeID: "",
    workTypeID: "",
    workDate: "",
    workStartTime: "",
    machineID: "",
    fuelCharge: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_ACTUAL_ENTRY_MACHINE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceTypeID: "",
    workTypeID: "",
    workFromDate: "",
    workToDate: "",
    machineID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_MACHINE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryMachineSuccess(data));
        } else {
          dispatch(getCivilActualEntryMachineSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryMachineSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryMachineSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_MACHINE,
    payload: data,
  };
};
export const updateCivilActualEntryMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workEndTime: "",
    offDuration: "",
    extraCharge: "",
    fuelCharge: "",
    bataCharge: "",
    workMeasurment: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_MACHINE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryMachineDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch({
            type: GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
            payload: [],
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        dispatch({
          type: GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
          payload: [],
        });
      });
  };
};

export const updateCivilActualEntryMachineDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workDate: "",
    workStartTime: "",
    workEndTime: "",
    offDuration: "",
    extraCharge: "",
    fuelCharge: "",
    machineRate: "",
    bataCharge: "",
    workMeasurment: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilActualEntryReviewStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workMeasurment: "",
    rejectionReasonID: "",
    rejectionRemark: "",
    qualityCheckPoint: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_REVIEW_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilActualEntryAppovalStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    status: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_APPROVAL_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

// ------
export const updateWorkOrderDueDate_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_WORK_ORDER_DUE_DATE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getActivityQualityCheckPoint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    taskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTIVITY_QUALITY_CHECK_POINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActivityQualityCheckPointSuccess(data));
        } else {
          dispatch(getActivityQualityCheckPointSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActivityQualityCheckPointSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getActivityQualityCheckPointSuccess = (data) => {
  return {
    type: GET_ACTIVITY_QUALITY_CHECK_POINT,
    payload: data,
  };
};
export const getActivityQualityCheckPointExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processID: "",
    taskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTIVITY_QUALITY_CHECK_POINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActivityQualityCheckPointExportSuccess(data));
        } else {
          dispatch(getActivityQualityCheckPointExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActivityQualityCheckPointExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getActivityQualityCheckPointExportSuccess = (data) => {
  return {
    type: GET_ACTIVITY_QUALITY_CHECK_POINT_EXPORT,
    payload: data,
  };
};
export const createActivityQualityCheckPoint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_ACTIVITY_QUALITY_CHECK_POINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateActivityQualityCheckPoint_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_ACTIVITY_QUALITY_CHECK_POINT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getActualRejectionReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonType: "",
    taskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTUAL_REJECTION_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActualRejectionReasonSuccess(data));
        } else {
          dispatch(getActualRejectionReasonSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActualRejectionReasonSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getActualRejectionReasonSuccess = (data) => {
  return {
    type: GET_ACTUAL_REJECTION_REASON,
    payload: data,
  };
};

export const getActualRejectionReasonExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    reasonType: "",
    taskID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTUAL_REJECTION_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActualRejectionReasonExportSuccess(data));
        } else {
          dispatch(getActualRejectionReasonExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActualRejectionReasonExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getActualRejectionReasonExportSuccess = (data) => {
  return {
    type: GET_ACTUAL_REJECTION_REASON_EXPORT,
    payload: data,
  };
};
export const createActualRejectionReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CRAETE_ACTUAL_REJECTION_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateActualRejectionReason_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_ACTIVITY_REJECTION_REASON,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryFilterVendor_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID:"",
    resourceCategoryID: "",
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceTypeID: "",
    workTypeID: "",
    workFromDate: "",
    workToDate: "",
    machineID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch({
            type: GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR,
            payload: [],
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        dispatch({
          type: GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR,
          payload: [],
        });
      });
  };
};
export const getCivilActualEntryFilterMachine_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID:"",
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceTypeID: "",
    workTypeID: "",
    workFromDate: "",
    workToDate: "",
    machineID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch({
            type: GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE,
            payload: [],
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        dispatch({
          type: GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE,
          payload: [],
        });
      });
  };
};

export const getActualEntryVendorLabour_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTUAL_ENTRY_VENDOR_LABOUR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch({
            type: GET_ACTUAL_ENTRY_VENDOR_LABOUR,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_ACTUAL_ENTRY_VENDOR_LABOUR,
            payload: [],
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        dispatch({
          type: GET_ACTUAL_ENTRY_VENDOR_LABOUR,
          payload: [],
        });
      });
  };
};

export const createCivilActualEntryLabour_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    workTypeID: "",
    workDate: "",
    workStartTime: "",
    vendorID: "",
    resourceID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_ACTUAL_ENTRY_LABOUR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getCivilActualEntryLabour_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceTypeID: "",
    workTypeID: "",
    workFromDate: "",
    workToDate: "",
    resourceID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_LABOUR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryLabourSuccess(data));
        } else {
          dispatch(getCivilActualEntryLabourSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryLabourSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryLabourSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_LABOUR,
    payload: data,
  };
};

export const getCivilActualEntryLabourDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryLabourDetailSuccess(data));
        } else {
          dispatch(getCivilActualEntryLabourDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryLabourDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryLabourDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL,
    payload: data,
  };
};

export const updateCivilActualEntryLabour_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workEndTime: "",
    offDuration: "",
    workMeasurment: "",
    resourceID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_LABOUR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilActualEntryLabourDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workDate: "",
    workStartTime: "",
    workEndTime: "",
    offDuration: "",
    workMeasurment: "",
    resourceID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getActualEntryVendorContract_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_ACTUAL_ENTRY_VENDOR_CONTRACT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getActualEntryVendorContractSuccess(data));
        } else {
          dispatch(getActualEntryVendorContractSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getActualEntryVendorContractSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getActualEntryVendorContractSuccess = (data) => {
  return {
    type: GET_ACTUAL_ENTRY_VENDOR_CONTRACT,
    payload: data,
  };
};

export const createCivilActualEntryContract_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanID: "",
    workTypeID: "",
    workDate: "",
    workStartTime: "",
    vendorID: "",
    resourceID: [],
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_ACTUAL_ENTRY_CONTRACT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryContract_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectPlanID: "",
    processPlanID: "",
    taskPlanID: "",
    resourceTypeID: "",
    workTypeID: "",
    workFromDate: "",
    workToDate: "",
    resourceID: "",
    vendorID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_CONTRACT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryContractSuccess(data));
        } else {
          dispatch(getCivilActualEntryContractSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryContractSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryContractSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_CONTRACT,
    payload: data,
  };
};

export const getCivilActualEntryContractDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryContractDetailSuccess(data));
        } else {
          dispatch(getCivilActualEntryContractDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryContractDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryContractDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL,
    payload: data,
  };
};

export const updateCivilActualEntryContract_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workEndTime: "",
    workMeasurment: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_CONTRACT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCivilActualEntryContractDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectActualID: "",
    workDate: "",
    workStartTime: "",
    workEndTime: "",
    offDuration: "",
    workMeasurment: "",
    resourceID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getGst_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_GST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getGstSuccess(data));
        } else {
          dispatch(getGstSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getGstSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getGstSuccess = (data) => {
  return {
    type: GET_GST,
    payload: data,
  };
};
export const getTds_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_TDS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getTdsSuccess(data));
        } else {
          dispatch(getTdsSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getTdsSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getTdsSuccess = (data) => {
  return {
    type: GET_TDS,
    payload: data,
  };
};
export const createCivilProjectBill_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    vendorID: "",
    categoryID: "",
    fromDate: "",
    toDate: "",
    tdsPercentage: "",
    advanceDeduction: "",
    gstID: "",
    vendorInvoiceNumber: "",
    vendorInvoiceDate: "",
    remark: "",
    billType: "",
    billItemIDs: [],
    isSeparateBill:"",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_BILL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCivilProjectBillLabour_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    vendorID: "",
    categoryID: "",
    fromDate: "",
    toDate: "",
    tdsPercentage: "",
    advanceDeduction: "",
    gstID: "",
    vendorInvoiceNumber: "",
    vendorInvoiceDate: "",
    remark: "",
    billType: "",
    billItemIDs: [],
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_BILL_LABOUR, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCivilProjectBillContract_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    companyID: "",
    projectID: "",
    vendorID: "",
    categoryID: "",
    fromDate: "",
    toDate: "",
    tdsPercentage: "",
    advanceDeduction: "",
    gstID: "",
    vendorInvoiceNumber: "",
    vendorInvoiceDate: "",
    remark: "",
    billType: "",
    billItemIDs: [],
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_BILL_CONTRACT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};
export const getWorkOrderFilterCompany_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    vendorID: "",
    projectID: "",
    status: "",
    companyID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_WORK_ORDER_FILTER_COMPANY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getWorkOrderFilterCompanySuccess(data));
        } else {
          dispatch(getWorkOrderFilterCompanySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getWorkOrderFilterCompanySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getWorkOrderFilterCompanySuccess = (data) => {
  return {
    type: GET_WORK_ORDER_FILTER_COMPANY,
    payload: data,
  };
};
export const getVendorMachineFilterVendor_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_VENDOR_MACHINE_FILTER_VENDOR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getVendorMachineFilterVendorSuccess(data));
        } else {
          dispatch(getVendorMachineFilterVendorSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVendorMachineFilterVendorSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getVendorMachineFilterVendorSuccess = (data) => {
  return {
    type: GET_VENDOR_MACHINE_FILTER_VENDOR,
    payload: data,
  };
};
export const getVendorMachineFilterMachineType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getVendorMachineFilterMachineTypeSuccess(data));
        } else {
          dispatch(getVendorMachineFilterMachineTypeSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getVendorMachineFilterMachineTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getVendorMachineFilterMachineTypeSuccess = (data) => {
  return {
    type: GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE,
    payload: data,
  };
};

export const getProjectMachineVendorMapFilterVendor_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    machineTypeID: "",
    vendorID: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectMachineVendorMapFilterVendorSuccess(data));
        } else {
          dispatch(getProjectMachineVendorMapFilterVendorSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMachineVendorMapFilterVendorSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectMachineVendorMapFilterVendorSuccess = (data) => {
  return {
    type: GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR,
    payload: data,
  };
};
export const getProjectMachineVendorMapFilterMachineType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    machineTypeID: "",
    vendorID: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectMachineVendorMapFilterMachineTypeSuccess(data));
        } else {
          dispatch(getProjectMachineVendorMapFilterMachineTypeSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectMachineVendorMapFilterMachineTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectMachineVendorMapFilterMachineTypeSuccess = (data) => {
  return {
    type: GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE,
    payload: data,
  };
};

export const getMaterial_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_MATERIAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getMaterialSuccess(data));
        } else {
          dispatch(getMaterialSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getMaterialSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getMaterialSuccess = (data) => {
  return {
    type: GET_MATERIAL,
    payload: data,
  };
};

export const getItemGroup_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_ITEM_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getItemGroupSuccess(data));
        } else {
          dispatch(getItemGroupSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getItemGroupSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getItemGroupSuccess = (data) => {
  return {
    type: GET_ITEM_GROUP,
    payload: data,
  };
};

export const getItemGroupExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_ITEM_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getItemGroupExportSuccess(data));
        } else {
          dispatch(getItemGroupExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getItemGroupExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getItemGroupExportSuccess = (data) => {
  return {
    type: GET_ITEM_GROUP_EXPORT,
    payload: data,
  };
};

export const createMaterial_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_MATERIAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateMaterial_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_MATERIAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const uploadMaterialImage_action = (data) => {
  let initialPayload = new FormData();

  for (var i = 0; i < data.documentFile.length; i++) {
    initialPayload.append("documentFile[]", data.documentFile[i].documentFile);

    // initialPayload.append('documentFile', data.documentFile);
  }

  initialPayload.append("userID", LoginuserId);
  initialPayload.append("materialID", data.materialID);

  const config = {
    headers: { "content-type": "multipart/form-data" },
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return FileUploadService.post(
      ErpApi.UPLOAD_MATERIAL_IMAGE,
      initialPayload,
      config
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
        console.error("Error uploading images:", err);
      });
  };
};

export const getAssetType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    groupID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_ASSET_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getAssetTypeSuccess(data));
        } else {
          dispatch(getAssetTypeSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAssetTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getAssetTypeSuccess = (data) => {
  return {
    type: GET_ASSET_TYPE,
    payload: data,
  };
};

export const getStockUom_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_STOCK_UOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getStockUomSuccess(data));
        } else {
          dispatch(getStockUomSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getStockUomSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getStockUomSuccess = (data) => {
  return {
    type: GET_STOCK_UOM,
    payload: data,
  };
};

export const createAssetType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_ASSET_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateAssetType_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_ASSET_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getAssetGroup_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "Active",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_ASSET_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getAssetGroupSuccess(data));
        } else {
          dispatch(getAssetGroupSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAssetGroupSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getAssetGroupSuccess = (data) => {
  return {
    type: GET_ASSET_GROUP,
    payload: data,
  };
};

export const createAssetGroup_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_ASSET_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateAssetGroup_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_ASSET_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createStockUom_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_STOCK_UOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateStockUom_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_STOCK_UOM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createItemGroup_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_ITEM_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateItemGroup_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    groupID: "",
    groupName: "",

    status: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_ITEM_GROUP, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const createCivilActivityMaterialSchedule_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanResourceID: "",
    requiredQuantity: "",
    requiredDate: "",
    remark: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_ACTIVITY_MATERIAL_SCHEDULE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    statusID: "",
    sortOrder: "",
    generalSearch: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          return data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        return [];
      });
  };
};
export const getCivilProjectTaskPlanMaterialSchedule_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanResourceID: "",
    projectID: "",
    processID: "",
    taskID: "",
    resourceCategoryID: "",
    resourceID: "",
    fromDate: "",
    toDate: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialScheduleSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialScheduleSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialScheduleSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialScheduleSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE,
    payload: data,
  };
};

export const updateCivilProjectTaskPlanMaterialSchedule_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    remark: "",
    indentItemID: [],
    status: "",
    reason: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectTaskPlanMaterialScheduleDetail_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanMaterialScheduleID: "",
    description: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialFilterProjectSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialFilterProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialFilterProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialFilterProcessSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialFilterProcessSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialFilterProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialFilterProcessSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialFilterTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    statusID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialFilterTaskSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialFilterTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialFilterTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialFilterTaskSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialFilterResource_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    statusID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialFilterResourceSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialFilterResourceSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialFilterResourceSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialFilterResourceSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterProject_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    fromDate: "",
    toDate: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterProjectSuccess(data)
          );
        } else {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterProjectSuccess([])
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(
          getCivilProjectTaskPlanMaterialScheduleFilterProjectSuccess([])
        );
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterProjectSuccess = (
  data
) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterProcess_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    fromDate: "",
    toDate: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterProcessSuccess(data)
          );
        } else {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterProcessSuccess([])
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(
          getCivilProjectTaskPlanMaterialScheduleFilterProcessSuccess([])
        );
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterProcessSuccess = (
  data
) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterTask_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    fromDate: "",
    toDate: "",
    statusID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterTaskSuccess(data)
          );
        } else {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterTaskSuccess({})
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialScheduleFilterTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterTaskSuccess = (
  data
) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterResource_action = (
  data
) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    taskID: "",
    resourceID: "",
    fromDate: "",
    toDate: "",
    statusID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterResourceSuccess(data)
          );
        } else {
          dispatch(
            getCivilProjectTaskPlanMaterialScheduleFilterResourceSuccess([])
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(
          getCivilProjectTaskPlanMaterialScheduleFilterResourceSuccess([])
        );
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialScheduleFilterResourceSuccess = (
  data
) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialScheduleExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanResourceID: "",
    projectID: "",
    processID: "",
    taskID: "",
    resourceCategoryID: "",
    resourceID: "",
    fromDate: "",
    toDate: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          return data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        return [];
      });
  };
};

export const getCivilProjectDrawing_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    drawingTypeID: "",
    projectID: "",
    statusID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: 10,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_DRAWING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectDrawing(data));
        } else {
          dispatch(getCivilProjectDrawing([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectDrawing([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectDrawing = (data) => {
  return {
    type: GET_CIVIL_PROJECT_DRAWING,
    payload: data,
  };
};
//filterDraWING
export const getCivilProjectDrawingFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromDate: "",
    toDate: "",
    categoryID: "",
    ownershipID: "",
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    priorityID: "",
    liaisonOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    projectZoneID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectDrawingFilterSuccess(data));
        } else {
          dispatch(getCivilProjectDrawingFilterSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectDrawingFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectDrawingFilterSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_DRAWING_FILTER,
    payload: data,
  };
};

export const get_Project_Drawing_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    documentStatus: "Active",
    referenceType: "ProjectTask",
    referenceID: "39",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_DOCUMENT_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getProjectDocumentDrawingType(data));
        } else {
          dispatch(getProjectDocumentDrawingType({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectDocumentDrawingType([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getProjectDocumentDrawingType = (data) => {
  return {
    type: GET_PROJECT_DOCUMENT_TYPE,
    payload: data,
  };
};

export const createCivilWorkInspection_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    description: "",
    actualEntryID: [],
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.CREATE_CIVIL_WORK_INSPECTION,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkInspection_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_WORK_INSPECTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkInspectionSuccess(data));
        } else {
          dispatch(getCivilWorkInspectionSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkInspectionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkInspectionSuccess = (data) => {
  return {
    type: GET_CIVIL_WORK_INSPECTION,
    payload: data,
  };
};

export const getCivilWorkInspectionExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_WORK_INSPECTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          return data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
        return [];
      });
  };
};

export const getCivilProjectWorkInspectionFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    // "projectPlanID":"",
    // "status":"",
    // "generalSearch":"",
    // "sortOrder":"",
    // "iDisplayStart":0,
    // "iDisplayLength":-1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectWorkInspectionFilterProjectSuccess(data));
        } else {
          dispatch(getCivilProjectWorkInspectionFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectWorkInspectionFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectWorkInspectionFilterProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_WORK_INSPECTION_FILTER_PROJECT,
    payload: data,
  };
};

export const getCivilWorkInspectionProcessPlanFilterProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    projectPlanID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkInspectionProcessPlanFilterProcessSuccess(data));
        } else {
          dispatch(getCivilWorkInspectionProcessPlanFilterProcessSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkInspectionProcessPlanFilterProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkInspectionProcessPlanFilterProcessSuccess = (data) => {
  return {
    type: GET_CIVIL_WORK_INSPECTION_FILTER_PROCESS,
    payload: data,
  };
};

export const getCivilWorkInspectionTaskPlanFilterTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    processPlanID: "",
    taskID: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getCivilWorkInspectionTaskPlanFilterTaskSuccess(data));
        } else {
          dispatch(getCivilWorkInspectionTaskPlanFilterTaskSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkInspectionTaskPlanFilterTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkInspectionTaskPlanFilterTaskSuccess = (data) => {
  return {
    type: GET_CIVIL_WORK_INSPECTION_TASK_PLAN_FILTER_TASK,
    payload: data,
  };
};

export const getCivilWorkInspectionDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    inspectionID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_WORK_INSPECTION_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilWorkInspectionDetailSuccess(data));
        } else {
          dispatch(getCivilWorkInspectionDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilWorkInspectionDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilWorkInspectionDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_WORK_INSPECTION_DETAIL,
    payload: data,
  };
};

export const createCivilQcTraining_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_QC_TRAINING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTraining_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_QC_TRAINING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingSuccess(data));
        } else {
          dispatch(getCivilQcTrainingSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING,
    payload: data,
  };
};

export const getCivilQcTrainingExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_QC_TRAINING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingExportSuccess(data));
        } else {
          dispatch(getCivilQcTrainingExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingExportSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING_EXPORT,
    payload: data,
  };
};

export const updateCivilQcTrainingAttendee_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    trainingID: "",
    attendeeID: "",
    attendeeName: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_QC_TRAINING_ATTENDEE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingAttendee_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    trainingID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_QC_TRAINING_ATTENDEE,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingAttendeeSuccess(data));
        } else {
          dispatch(getCivilQcTrainingAttendeeSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingAttendeeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingAttendeeSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING_ATTENDEE,
    payload: data,
  };
};

export const getCivilQcTrainingDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    trainingID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_QC_TRAINING_DETAIL,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingDetailSuccess(data));
        } else {
          dispatch(getCivilQcTrainingDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING_DETAIL,
    payload: data,
  };
};

export const updateCivilQcTrainingStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    trainingID: "",
    status: "",
    remark: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.UPDATE_CIVIL_QC_TRAINING_STATUS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const updateCivilQcTraining_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    trainingID: "",
    activityID: "",
    description: "",
    trainingDate: "",
    timeFrom: "",
    timeTo: "",
    whom: "",
    status: "",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_QC_TRAINING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_QC_TRAINING_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingFilterProjectSuccess(data));
        } else {
          dispatch(getCivilQcTrainingFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingFilterProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING_FILTER_PROJECT,
    payload: data,
  };
};

export const getCivilQcTrainingFilterProcess_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_QC_TRAINING_FILTER_PROCESS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingFilterProcessSuccess(data));
        } else {
          dispatch(getCivilQcTrainingFilterProcessSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingFilterProcessSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingFilterProcessSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING_FILTER_PROCESS,
    payload: data,
  };
};

export const getCivilQcTrainingFilterTask_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectID: "",
    processID: "",
    activityID: "",
    status: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_QC_TRAINING_FILTER_TASK,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilQcTrainingFilterTaskSuccess(data));
        } else {
          dispatch(getCivilQcTrainingFilterTaskSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilQcTrainingFilterTaskSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilQcTrainingFilterTaskSuccess = (data) => {
  return {
    type: GET_CIVIL_QC_TRAINING_FILTER_TASK,
    payload: data,
  };
};

export const getCivilProjectTaskPlanMaterialScheduleDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    taskPlanMaterialScheduleID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectTaskPlanMaterialScheduleDetailSuccess(data));
        } else {
          dispatch(getCivilProjectTaskPlanMaterialScheduleDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectTaskPlanMaterialScheduleDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectTaskPlanMaterialScheduleDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS,
    payload: data,
  };
};

export const updateIndentItem_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_INDENT_ITEM, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateQuotationDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_QUOTATION_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateCivilProjectTaskPlanMaterialScheduleSendForApproval_action =
  (data) => {
    const initialPayload = {
      userID: LoginuserId,
      scheduleItemID: [],
      ...data,
    };

    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(
        ErpApi.UPDATE_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_SEND_FOR_APPROVAL,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());

          if (!data.error) {
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(mainLoadingFalse());
        });
    };
  };

export const updateGrn_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    grnID: "",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_GRN, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectApproval_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    projectTaskPlanID: "",
    projectPlanID: "",
    processPlanID: "",
    statusID: "",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_APPROVAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectApprovalSuccess(data));
        } else {
          dispatch(getCivilProjectApprovalSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectApprovalSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectApprovalSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_APPROVAL,
    payload: data,
  };
};

export const getCivilProjectBill_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectBillSuccess(data));
        } else {
          dispatch(getCivilProjectBillSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectBillSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectBillSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_BILL,
    payload: data,
  };
};


export const getCivilProjectBillExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectBillExportSuccess(data));
        } else {
          dispatch(getCivilProjectBillExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectBillExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectBillExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_BILL_EXPORT,
    payload: data,
  };
};

export const getCivilProjectBillDetail_action = (data) => {

  const initialPayload = {
  "userID": LoginuserId,
  "billID": "",
  ...data
  };

  return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL_DETAIL, initialPayload).then((response) => {
              const { data } = response;
              dispatch(mainLoadingFalse());

              if (!data.error) {
                  dispatch(getCivilProjectBillDetailSuccess(data));
              } else {
                  dispatch(getCivilProjectBillDetailSuccess([]));
              }
          })
          .catch((err) => {
              console.log("err", err);
              dispatch(getCivilProjectBillDetailSuccess([]));
              dispatch(mainLoadingFalse());
          });
  };
};

export const getCivilProjectBillDetailSuccess = (data) => {
  return {
      type: GET_CIVIL_PROJECT_BILL_DETAIL,
      payload: data,
  };
}
export const updateCivilProjectBillDetail_action = (data) => {

const initialPayload = {
  "userID": LoginuserId,
  "billID": "",
  "companyID": "",
  "tdsPercentage": "",
  "gstID": "",
  "advanceDeduction": "",
  "vendorInvoiceNumber": "",
  "vendorInvoiceDate": "",
  "fromDate": "",
  "toDate": "",
  "remark": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_BILL_DETAIL, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
              dispatch(displayMessage({
                text : data.message,
                type : "success"
            }));
            } else {
              dispatch(displayMessage({
                text : data.message,
                type : "error"
            }));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(mainLoadingFalse());
        });
};
};

export const civilProjectBillSendForApproval_action = (data) => {

const initialPayload = {
"userID": LoginuserId,
"billingID": "",
"status": "",
"remark": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CIVIL_PROJECT_BILL_SEND_FOR_APPROVAL, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
              dispatch(displayMessage({
                text : data.message,
                type : "success"
            }));
            } else {
              dispatch(displayMessage({
                text : data.message,
                type : "error"
            }));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(mainLoadingFalse());
        });
};
};
export const updateCivilProjectBillGenerate_action = (data) => {

const initialPayload = {
"userID": LoginuserId,
"billID": "",
"status": "",
"remark": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_BILL_GENERATE, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
              dispatch(displayMessage({
                text : data.message,
                type : "success"
            }));
            } else {
              dispatch(displayMessage({
                text : data.message,
                type : "error"
            }));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(mainLoadingFalse());
        });
};
};
export const getCivilProjectBillFilterProject_action = (data) => {

const initialPayload = {
"userID": LoginuserId,
"companyID": "",
"projectID": "",
"categoryID": "",
"vendorID": "",
"status": "",
"isAccount": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL_FILTER_PROJECT, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectBillFilterProjectSuccess(data));
            } else {
                dispatch(getCivilProjectBillFilterProjectSuccess([]));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(getCivilProjectBillFilterProjectSuccess([]));
            dispatch(mainLoadingFalse());
        });
};
};
export const getCivilProjectBillFilterProjectSuccess = (data) => {
return {
    type: GET_CIVIL_PROJECT_BILL_FILTER_PROJECT,
    payload: data,
};
}
export const getCivilProjectBillFilterCompany_action = (data) => {

const initialPayload = {
"userID": LoginuserId,
"companyID": "",
"projectID": "",
"categoryID": "",
"vendorID": "",
"status": "",
"isAccount": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL_FILTER_COMPANY, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectBillFilterCompanySuccess(data));
            } else {
                dispatch(getCivilProjectBillFilterCompanySuccess([]));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(getCivilProjectBillFilterCompanySuccess([]));
            dispatch(mainLoadingFalse());
        });
};
};

export const getCivilProjectBillFilterCompanySuccess = (data) => {
return {
    type: GET_CIVIL_PROJECT_BILL_FILTER_COMPANY,
    payload: data,
};
}
export const getCivilProjectBillFilterVendor_action = (data) => {

const initialPayload = {
"userID": LoginuserId,
"companyID": "",
"projectID": "",
"categoryID": "",
"vendorID": "",
"status": "",
"isAccount": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL_FILTER_VENDOR, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectBillFilterVendorSuccess(data));
            } else {
                dispatch(getCivilProjectBillFilterVendorSuccess([]));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(getCivilProjectBillFilterVendorSuccess([]));
            dispatch(mainLoadingFalse());
        });
};
};

export const getCivilProjectBillFilterVendorSuccess = (data) => {
return {
    type: GET_CIVIL_PROJECT_BILL_FILTER_VENDOR,
    payload: data,
};
}
export const getCivilProjectBillFilterCategory_action = (data) => {

const initialPayload = {
"userID": LoginuserId,
"companyID": "",
"projectID": "",
"categoryID": "",
"vendorID": "",
"status": "",
"isAccount": "",
...data
};

return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getCivilProjectBillFilterCategorySuccess(data));
            } else {
                dispatch(getCivilProjectBillFilterCategorySuccess([]));
            }
        })
        .catch((err) => {
            console.log("err", err);
            dispatch(getCivilProjectBillFilterCategorySuccess([]));
            dispatch(mainLoadingFalse());
        });
};
};

export const getCivilProjectBillFilterCategorySuccess = (data) => {
return {
    type: GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY,
    payload: data,
};
}



export const getCivilActualEntryFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    "projectID":"",
    "projectPlanID":"",
    "processPlanID":"",
    "taskPlanID":"",
    "resourceTypeID":"",
    "workTypeID":"",
    "workFromDate":"",
    "workToDate":"",
     "machineID":"",
    "vendorID":"",
    "resourceCategoryID":"",
    "status":"",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryFilterProjectSuccess(data));
        } else {
          dispatch(getCivilActualEntryFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryFilterProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT,
    payload: data,
  };
};



export const getCivilActualEntryFilterWbs_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryFilterWbsSuccess(data));
        } else {
          dispatch(getCivilActualEntryFilterWbsSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryFilterWbsSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryFilterWbsSuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS,
    payload: data,
  };
};

export const getCivilActualEntryFilterActivity_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilActualEntryFilterActivitySuccess(data));
        } else {
          dispatch(getCivilActualEntryFilterActivitySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilActualEntryFilterActivitySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilActualEntryFilterActivitySuccess = (data) => {
  return {
    type: GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY,
    payload: data,
  };
};


export const getCivilProjectForActualEntry_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectForActualEntrySuccess(data));
        } else {
          dispatch(getCivilProjectForActualEntrySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectForActualEntrySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectForActualEntrySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY,
    payload: data,
  };
};


export const getCivilProjectWbsForActualEntry_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectWbsForActualEntrySuccess(data));
        } else {
          dispatch(getCivilProjectWbsForActualEntrySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectWbsForActualEntrySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectWbsForActualEntrySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY,
    payload: data,
  };
};


export const getCivilProjectActivityForActualEntry_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,

    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(
      ErpApi.GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY,
      initialPayload
    )
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectActivityForActualEntrySuccess(data));
        } else {
          dispatch(getCivilProjectActivityForActualEntrySuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectActivityForActualEntrySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectActivityForActualEntrySuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY,
    payload: data,
  };
};


export const getCivilSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilSettingSuccess(data));
        } else {
          dispatch(getCivilSettingSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilSettingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilSettingSuccess = (data) => {
  return {
    type: GET_CIVIL_SETTING,
    payload: data,
  };
};


export const createCivilSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
  
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};




export const UpdateCivilSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
  
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};




export const getCivilSettingExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilSettingSuccessExport(data));
        } else {
          dispatch(getCivilSettingSuccessExport([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilSettingSuccessExport([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilSettingSuccessExport = (data) => {
  return {
    type: GET_CIVIL_SETTING_EXPORT,
    payload: data,
  };
};




export const getCivilProjectSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    civilSettingID:"",
    projectID:"",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSettingSuccess(data));
        } else {
          dispatch(getCivilProjectSettingSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSettingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSettingSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SETTING,
    payload: data,
  };
};

export const getCivilProjectSettingFilter_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    fromDate: "",
    toDate: "",
    categoryID: "",
    ownershipID: "",
    projectID: "",
    projectPhaseID: "",
    planningAuthorityID: "",
    bdOwnerID: "",
    legalOwnerID: "",
    priorityID: "",
    liaisonOwnerID: "",
    civilOwnerID: "",
    projectStatus: "Active",
    projectZoneID: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: 0,
    iDisplayLength: -1,
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSettingFilterSuccess(data));
        } else {
          dispatch(getCivilProjectSettingFilterSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSettingFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSettingFilterSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SETTING_FILTER,
    payload: data,
  };
};





export const CreateCivilProjectSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
  
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_CIVIL_PROJECT_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};





export const getCivilProjectSettingDetail_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
   
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SETTING_DETAILS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSettingDetailSuccess(data));
        } else {
          dispatch(getCivilProjectSettingDetailSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSettingDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSettingDetailSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SETTING_DETAILS,
    payload: data,
  };
};




export const UpdateCivilProjectSettingStatus_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
  
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_SETTING_STATUS, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};





export const UpdateCivilProjectSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
  
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_CIVIL_PROJECT_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};


export const getCivilProjectSettingExport_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    civilSettingID:"",
    projectID:"",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "10",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SETTING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSettingExportSuccess(data));
        } else {
          dispatch(getCivilProjectSettingExportSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSettingExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSettingExportSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SETTING_EXPORT,
    payload: data,
  };
};



export const getCivilProjectSettingFilterProject_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    civilSettingID:"",
    projectID:"",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSettingFilterProjectSuccess(data));
        } else {
          dispatch(getCivilProjectSettingFilterProjectSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSettingFilterProjectSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSettingFilterProjectSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT,
    payload: data,
  };
};


export const getCivilProjectSettingFilterCivilSetting_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    civilSettingID:"",
    projectID:"",
    status: "",
    generalSearch: "",
    sortOrder: "",
    iDisplayStart: "0",
    iDisplayLength: "-1",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getCivilProjectSettingFilterCivilSettingSuccess(data));
        } else {
          dispatch(getCivilProjectSettingFilterCivilSettingSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getCivilProjectSettingFilterCivilSettingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getCivilProjectSettingFilterCivilSettingSuccess = (data) => {
  return {
    type: GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL,
    payload: data,
  };
};



export const getProjectBillAdvanceDeductionPercentage_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    actualID:"",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_BILL_ADVANCE_DEDUCTION_PERCENTAGE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectBillAdvanceDeductionPercentageSuccess(data));
        } else {
          dispatch(getProjectBillAdvanceDeductionPercentageSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBillAdvanceDeductionPercentageSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectBillAdvanceDeductionPercentageSuccess = (data) => {
  return {
    type: GET_PROJECT_BILL_ADVANCE_DEDUCTION_PERCENTAGE,
    payload: data,
  };
};



export const getProjectBillRetentionPercentage_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    billCategoryID:"",
    ...data,
  };

  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_PROJECT_BILL_RETENTION_PERCENTAGE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());

        if (!data.error) {
          dispatch(getProjectBillRetentionPercentageSuccess(data));
        } else {
          dispatch(getProjectBillRetentionPercentageSuccess([]));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getProjectBillRetentionPercentageSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};

export const getProjectBillRetentionPercentageSuccess = (data) => {
  return {
    type: GET_PROJECT_BILL_RETENTION_PERCENTAGE,
    payload: data,
  };
};


