export const GET_MATERIAL = "GET_MATERIAL";
export const GET_MATERIAL_EXPORT = "GET_MATERIAL_EXPORT";
export const GET_INDENT = "GET_INDENT";
export const GET_INDENT_DETAIL = "GET_INDENT_DETAIL";
export const GET_INDENT_FILTER_PROJECT = "GET_INDENT_FILTER_PROJECT";
export const GET_INDENT_FILTER_ITEM = "GET_INDENT_FILTER_ITEM";
export const GET_INDENT_FOR_QUOTATION = "GET_INDENT_FOR_QUOTATION";
export const GET_INDENT_ITEM_FOR_QUOTATION = "GET_INDENT_ITEM_FOR_QUOTATION";
export const GET_QUOTATION = "GET_QUOTATION";
export const GET_QUOTATION_DETAIL = "GET_QUOTATION_DETAIL";
export const GET_QUOTATION_FILTER_PROJECT = "GET_QUOTATION_FILTER_PROJECT";
export const GET_QUOTATION_FILTER_ITEM = "GET_QUOTATION_FILTER_ITEM";
export const GET_QUOTATION_FILTER_VENDOR = "GET_QUOTATION_FILTER_VENDOR";
export const GET_BRAND = "GET_BRAND";
export const GET_PURCHASE_ORDER_CHARGE_MASTER =
  "GET_PURCHASE_ORDER_CHARGE_MASTER";
export const GET_PROJECT_ADDRESS = "GET_PROJECT_ADDRESS";
export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER";
export const GET_PURCHASE_ORDER_DETAIL = "GET_PURCHASE_ORDER_DETAIL";
export const GET_PURCHASE_ORDER_FILTER_PROJECT =
  "GET_PURCHASE_ORDER_FILTER_PROJECT";
export const GET_PURCHASE_ORDER_FILTER_ENTITY =
  "GET_PURCHASE_ORDER_FILTER_ENTITY";
export const GET_PURCHASE_ORDER_FILTER_VENDOR =
  "GET_PURCHASE_ORDER_FILTER_VENDOR";
export const GET_PURCHASE_ORDER_FOR_GRN = "GET_PURCHASE_ORDER_FOR_GRN";
export const GET_PURCHASE_ORDER_ITEM_FOR_GRN =
  "GET_PURCHASE_ORDER_ITEM_FOR_GRN";
export const GET_GRN = "GET_GRN";
export const GET_GRN_DETAIL = "GET_GRN_DETAIL";
export const GET_MATERIAL_DETAIL = "GET_MATERIAL_DETAIL";
export const GET_MATERIAL_BRAND = "GET_MATERIAL_BRAND";
export const GET_CREDIT_DURATION = "GET_CREDIT_DURATION";
export const GET_PURCHASE_ORDER_EXPORT = "GET_PURCHASE_ORDER_EXPORT";
export const GET_GRN_EXPORT = "GET_GRN_EXPORT";
export const GET_INDENT_EXPORT = "GET_INDENT_EXPORT";
export const GET_QUOTATION_EXPORT = "GET_QUOTATION_EXPORT";
export const GET_INDENT_APPROVAL = "GET_INDENT_APPROVAL";
export const GET_QUOTATION_APPROVAL = "GET_QUOTATION_APPROVAL";
export const GET_QUOTATION_FOR_PURCHASE_ORDER =
  "GET_QUOTATION_FOR_PURCHASE_ORDER";
export const GET_PURCHASE_ORDER_APPROVAL = "GET_PURCHASE_ORDER_APPROVAL";
export const GET_PROJECT_MATERIAL_STOCK = "GET_PROJECT_MATERIAL_STOCK";
export const GET_PROJECT_MATERIAL_STOCK_EXPORT =
  "GET_PROJECT_MATERIAL_STOCK_EXPORT";
export const GET_CHECK_POINT = "GET_CHECK_POINT";
export const GET_GRN_CHECK_POINT_MATERIAL = "GET_GRN_CHECK_POINT_MATERIAL";
export const GET_PRC_GRN_CHECK_POINT_MATERIAL =
  "GET_PRC_GRN_CHECK_POINT_MATERIAL";
export const GET_INDENT_FILTER_REQUESTED_BY = "GET_INDENT_FILTER_REQUESTED_BY";
export const GET_PROJECT_MATERIAL_REQUEST = "GET_PROJECT_MATERIAL_REQUEST";
export const GET_MATERIAL_REQUEST_INDENT_INFO =
  "GET_MATERIAL_REQUEST_INDENT_INFO";
export const GET_STOCK_TRANSFER_MATERIAL = "GET_STOCK_TRANSFER_MATERIAL";
export const GET_PROJECT_MATERIAL_REQUEST_INDENT =
  "GET_PROJECT_MATERIAL_REQUEST_INDENT";
export const GET_PROJECT_MATERIAL_REQUEST_DETAIL =
  "GET_PROJECT_MATERIAL_REQUEST_DETAIL";
export const GET_PROJECT_MATERIAL_REQUEST_FILTER_PROJECT =
  "GET_PROJECT_MATERIAL_REQUEST_FILTER_PROJECT";
export const GET_PROJECT_MATERIAL_REQUEST_FILTER_PROCESS =
  "GET_PROJECT_MATERIAL_REQUEST_FILTER_PROCESS";
export const GET_PROJECT_MATERIAL_REQUEST_FILTER_MATERIAL =
  "GET_PROJECT_MATERIAL_REQUEST_FILTER_MATERIAL";
export const GET_STOCK_TRANSFER = "GET_STOCK_TRANSFER";
export const GET_PROJECT_MATERIAL_STOCK_TRANSFER =
  "GET_PROJECT_MATERIAL_STOCK_TRANSFER";
export const GET_PROJECT_MATERIAL_STOCK_DESTINATION =
  "GET_PROJECT_MATERIAL_STOCK_DESTINATION";
export const GET_SOURCE_PROJECT = "GET_SOURCE_PROJECT";
export const GET_DESTINATION_PROJECT = "GET_DESTINATION_PROJECT";
export const GET_PROJECT_MATERIAL_STOCK_TRANSFER_DETAIL =
  "GET_PROJECT_MATERIAL_STOCK_TRANSFER_DETAIL";
export const GET_STOCK_TRANSFER_SOURCE_PROJECT_FILTER =
  "GET_STOCK_TRANSFER_SOURCE_PROJECT_FILTER";
export const GET_STOCK_TRANSFER_DESTINATION_PROJECT_FILTER =
  "GET_STOCK_TRANSFER_DESTINATION_PROJECT_FILTER";
export const GET_STOCK_TRANSFER_MATERIAL_PROJECT_FILTER =
  "GET_STOCK_TRANSFER_MATERIAL_PROJECT_FILTER";
export const GET_STOCK_TRANSFER_EXPORT = "GET_STOCK_TRANSFER_EXPORT";
export const GET_PROJECT_MATERIAL_REQUEST_FILTER_TASK =
  "GET_PROJECT_MATERIAL_REQUEST_FILTER_TASK";
export const GET_PURCHASE_ORDER_FOR_INVOICE = "GET_PURCHASE_ORDER_FOR_INVOICE";
export const GET_GRN_FOR_PURCHASE_INVOICE = "GET_GRN_FOR_PURCHASE_INVOICE";
export const GET_GRN_DEBIT_NOTE_ITEM_FOR_PURCHASE_INVOICE =
  "GET_GRN_DEBIT_NOTE_ITEM_FOR_PURCHASE_INVOICE";
export const GET_DELIVERY_CHALLAN = "GET_DELIVERY_CHALLAN";
export const GET_DELIVERY_CHALLAN_EXPORT = "GET_DELIVERY_CHALLAN_EXPORT";
export const GET_DELIVERY_CHALLAN_DETAIL = "GET_DELIVERY_CHALLAN_DETAIL";
export const GET_STOCK_TRANSFER_FOR_DELIVERY_CHALLAN =
  "GET_STOCK_TRANSFER_FOR_DELIVERY_CHALLAN";
export const GET_DELIVERY_CHALLAN_APPROVED = "GET_DELIVERY_CHALLAN_APPROVED";
export const GET_DELIVERY_CHALLAN_PRINT = "GET_DELIVERY_CHALLAN_PRINT";
export const GET_DELIVERY_CHALLAN_FILTER_FROM_PROJECT="GET_DELIVERY_CHALLAN_FILTER_FROM_PROJECT";
export const GET_DELIVERY_CHALLAN_FILTER_FROM_COMPANY="GET_DELIVERY_CHALLAN_FILTER_FROM_COMPANY";
//Purchase Invoice 
export const GET_PURCHASE_INVOICE = "GET_PURCHASE_INVOICE";
export const GET_PURCHASE_INVOICE_EXPORT = "GET_PURCHASE_INVOICE_EXPORT";
export const GET_PURCHASE_INVOICE_DETAIL = "GET_PURCHASE_INVOICE_DETAIL";