import React from "react";
import Modal from "../Components/Modal";
import { Box, Button, Grid } from "@mui/material";
const SessionTimeoutDialog = ({  open, countdown, onLogout,onContinue }) => {
  return (
    <Modal
    label={"Session Timeout"}
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    // okButton={closeModal}
    // closeButton={closeModal}
    hidefooter={true}
  >
    <div className="modal-body">
      <div className="modal-body-content">
        <div className="modal-body-content-text">
          <p>
            Your current session will end in {" "}
            <span className={countdown}>{countdown}</span> seconds.
          </p>
          <p>Do you want to continue the session?</p>
        </div>
      </div>
    </div>
    <div className="modal-footer">
    <Grid className="d-flex justify-content-end" p={2}>
    <Box mx={2}>
      <Button 
        className="btn btn-error" 
        onClick={onLogout} 
        variant="contained" color="error"
        >
      Logout
      </Button>
      </Box>
      <Box>
      <Button 
        className="btn btn-primary" 
        onClick={onContinue}
        variant="contained"
        >
      Continue Session
      </Button>
      </Box>
    </Grid>
    </div>
  </Modal>
  );
}
export default SessionTimeoutDialog;